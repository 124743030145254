import React from "react"
import "../index.css"
import { Box, Center, Grid, GridItem, Image } from "@chakra-ui/react"
import { contentFeature } from "../../utils/descriptions"
import { Trans } from "gatsby-plugin-react-i18next"
import { contentHeader } from "./../../utils/descriptions"

const HomeFeatures = () => {
  return (
    <>
      <Box mt={20} mx={{ xl: "15rem", lg: "12rem", base: "1rem" }}>
        <Center>
          <Box
            fontWeight={600}
            fontSize={{ lg: 18, base: 16 }}
            color="#8326ea"
            letterSpacing={"wider"}
          >
            <Trans>Features</Trans>
          </Box>
        </Center>
        <Box
          fontSize={{ "2xl": 45, xl: 35, lg: 25, sm: 20, base: 20 }}
          textAlign={"center"}
          fontWeight={{ lg: 700, base: 600 }}
          py="3"
        >
          <Trans>{contentHeader.featTitle}</Trans>
        </Box>
        <Box
          mx={{ xl: "10rem", lg: "7rem", sm: "3rem", base: "3rem" }}
          textAlign={"center"}
          fontSize={{ lg: 17, sm: 15, base: 15 }}
          display={["none", "none", "block", "block"]}
        >
          <Trans>{contentHeader.featDesc}</Trans>
        </Box>
      </Box>

      <Box my={20} mx={{ xl: "8rem", lg: "5rem", base: "1rem" }}>
        <Grid
          templateColumns={{ xl: "repeat(3, 1fr)", base: "repeat(2, 1fr)" }}
          gap={{ xl: 16, lg: 14, base: 12 }}
        >
          {contentFeature.map(content => (
            <GridItem textAlign={"center"} key={content.featTitle}>
              <Box boxSize="sm" w="100%" h="auto">
                <Center>
                  <Image
                    src={content.img}
                    alt="img"
                    w={{ xl: "60%", sm: "50%", base: "80%" }}
                    mb="0"
                    mr="0"
                    maxH="150"
                  />
                </Center>
              </Box>
              <Box
                w="100%"
                fontSize={{ xl: 25, lg: 21, base: 18 }}
                fontWeight={600}
                my={4}
              >
                <Trans>{content.featTitle}</Trans>
              </Box>
              <Box
                w="100%"
                fontSize={{ lg: 15, base: 14 }}
                fontWeight={500}
                display={["none", "none", "block", "block"]}
              >
                <Trans>{content.featDesc}</Trans>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default HomeFeatures
